import React from "react";
import Static, { Item } from "../../components/commen/Static";

const Shipping = () => {
  return (
    <Static title={"Shipping Policy"}>
      <Item title={""}>
        SHIPPING POLICY
        <br />
        <br />
        https://tulsiapp.com/ is committed to excellence, and the full
        satisfaction of our customers.
        <br />
        <br />
        https://tulsiapp.com/ proudly offers shipping services. Be assured we
        are doing everything in our
        <br />
        <br />
        power to get your order to you as soon as possible. Please consider any
        holidays that might impact
        <br />
        <br />
        delivery times. https://tulsiapp.com/ also offers same day dispatch.
        <br />
        <br />
        1. SHIPPING
        <br />
        <br />
        All orders for our products are processed and shipped out in 4-10
        business days. Orders are not
        <br />
        <br />
        shipped or delivered on weekends or holidays. If we are experiencing a
        high volume of orders,
        <br />
        <br />
        shipments may be delayed by a few days. Please allow additional days in
        transit for delivery. If there
        <br />
        <br />
        will be a significant delay in the shipment of your order, we will
        contact you via email.
        <br />
        <br />
        2. WRONG ADDRESS DISCLAIMER
        <br />
        <br />
        It is the responsibility of the customers to make sure that the shipping
        address entered is correct.
        <br />
        <br />
        We do our best to speed up processing and shipping time, so there is
        always a small window to
        <br />
        <br />
        correct an incorrect shipping address. Please contact us immediately if
        you believe you have
        <br />
        <br />
        provided an incorrect shipping address.
        <br />
        <br />
        3. UNDELIVERABLE ORDERS
        <br />
        <br />
        Orders that are returned to us as undeliverable because of incorrect
        shipping information are
        <br />
        <br />
        subject to a restocking fee to be determined by us.
        <br />
        <br />
        4. LOST/STOLEN PACKAGES
        <br />
        <br />
        https://tulsiapp.com/ is not responsible for lost or stolen packages. If
        your tracking information states
        <br />
        <br />
        that your package was delivered to your address and you have not
        received it, please report to the
        <br />
        <br />
        local authorities.
        <br />
        <br />
        5. RETURN REQUEST DAYS
        <br />
        <br />
        https://tulsiapp.com/ allows you to return its item (s) within a period
        of 0 days. Kindly be advised that
        <br />
        <br />
        the item (s) should be returned unopened and unused.
        <br />
        <br />
        6. OUT OF STOCK ITEM PROCESS
        <br />
        <br />
        In case of out-of-stock, https://tulsiapp.com Send available items first
        and send remaining items when items arrive back in stock
        <br />
        <br />
        7. IMPORT DUTY AND TAXES
        <br />
        <br />
        When working with https://tulsiapp.com/, you have the following options
        when it comes to taxes as well and import taxes: pre-paid and included
        in price of order
        <br />
        <br />
        8. ACCEPTANCE
        <br />
        <br />
        By accessing our site and placing an order you have willingly accepted
        the terms of this Shipping Policy.
        <br />
        <br /> 9. CONTACT INFORMATION
        <br />
        <br />
        In the event you have any questions or comments please reach us via the
        following contacts:
        <br />
        <br />
        Email - support@tulsiapp.com
      </Item>
    </Static>
  );
};

export default Shipping;
