import React, { useEffect } from "react";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Input from "../components/commen/Input";
import Loading from "../components/commen/Loading";
import Modal from "../components/commen/Modal";
import {
  buySubscription,
  generateRandomString,
  getAllSubscriptions,
  getOtherSubs,
  getSubStatus,
  phonePay,
  updateSubscription,
  url,
} from "../utils/apis";
import useStore from "../utils/store";

const AllSubscrition = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const getOwnSubscriptions = async () => {
    setLoading(true);
    const [success, response] = !profile?.subscriptionValid
      ? await getAllSubscriptions()
      : await getOtherSubs(user.token);
    if (success) {
      setSubscriptions(response);
    }
    setLoading(false);
  };


  const checkAccessHandler = async () => {
    const [success, response] = await getSubStatus(user?.token);
    if (success) {
      setProfile(response);
    }
  };

  const buySubscriptionHandler = async (id) => {
    if (!user) {
      navigate("/login");
    }
    setLoading(true);
    const [success, response] = !profile?.subscriptionValid
      ? await buySubscription(user.token, {
          subscriptionId: id,
        })
      : await updateSubscription(user.token, {
          subscriptionId: id,
        });
    if (success) {
      getOwnSubscriptions();
      setSuccess(true);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getOwnSubscriptions();
  }, [profile]);

  React.useEffect(() => {
    checkAccessHandler();
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="flex flex-1 justify-center py-10">
      <div className="grid w-[80%] gap-10  md:grid-cols-2 lg:grid-cols-3">
        {subscriptions?.map((item) => (
          <Card
            data={item}
            onClick={() => buySubscriptionHandler(item._id)}
            key={item._id}
          />
        ))}
      </div>
      {success && (
        <Modal>
          <div className="flex flex-col items-center justify-center gap-4 rounded bg-white py-10">
            <BsFillPatchCheckFill className="text-7xl text-green-600" />
            <div className="flex flex-col items-center gap-1">
              <h1 className="text-xl font-semibold">
                You have successfully subscribed
              </h1>
              <p className="text-sm  text-gray-600">
                Thanks for subscribing to our service
              </p>
            </div>
            <button
              onClick={() => navigate("/my-account")}
              className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-white"
            >
              Enjoy Watching
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AllSubscrition;

export const Card = ({ data, onClick }) => {
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const router = useNavigate();
  const payHandler = async () => {
    setLoading(true);
    const [success, response] = await phonePay(
      {
        amount: data?.price * 100,
        callbackUrl: `${url}/callback?purchaseType=subscription&id=${data?._id}&userId=${user?.user?._id}`,
        deviceContext: {
          deviceOS: "ANDROID",
        },
        merchantId: "M22Q776MBMAWU",
        redirectUrl: `https://www.tulsiapp.com/`,
        redirectMode: "GET",
        merchantTransactionId: generateRandomString(10).toUpperCase(),
        merchantUserId: user?.user?._id,
        mobileNumber: user?.user?.phoneNumber ?? 9876543210,
        paymentInstrument: {
          type: "PAY_PAGE",
        },
      },
      user.token
    );

    if (success) {
      window.location.replace(
        response?.data?.data?.instrumentResponse?.redirectInfo?.url
      );
    }
    setLoading(false);
  };

  const buySubscriptionHandler = async () => {
    setLoading(true);
    const [success, response] = await buySubscription(user.token, {
      subscriptionId: data._id,
    });
    if (success) {
      router("/success", { state: response });
    }
    setLoading(false);
  };
  const [Edit, setEdit] = React.useState(false);
  const [newData, setNewData] = React.useState({
    name: "",
    phoneNumber: "",
    email: "",
  });

  useEffect(() => {
    if (user) {
      setNewData({
        name: user?.user?.name,
        phoneNumber: user?.user?.phoneNumber,
        email: user?.user?.email,
      });
    }
  }, [user]);

  return (
    <div className=" h-fit  overflow-hidden rounded-md ">
      {Edit && (
        <Modal
          onClose={() => {
            setEdit(false);
          }}
          height="h-auto"
        >
          <div className="flex h-full flex-col items-center justify-center rounded bg-secondary3 py-5">
            <h1 className="mb-2 text-2xl font-bold text-text">
              Buy Subscription
            </h1>
            <div className="flex flex-col items-center justify-center gap-4">
              <Input
                type="text"
                placeholder="Name"
                value={newData.name}
                onChange={(e) => {
                  setNewData({ ...newData, name: e.target.value });
                }}
              />

              {user.loginWith === "phone" ? (
                <Input
                  type="text"
                  placeholder="Phone Number"
                  value={newData.phoneNumber}
                  onChange={(e) => {
                    setNewData({ ...newData, phoneNumber: e.target.value });
                  }}
                />
              ) : (
                <Input
                  type="text"
                  placeholder="Email"
                  value={newData.email}
                  onChange={(e) => {
                    setNewData({ ...newData, email: e.target.value });
                  }}
                />
              )}
              <div className="flex w-full justify-between font-bold text-text">
                <p>Price:</p>
                <p className="text-lg"> {data?.price}</p>
              </div>
              <div className="flex w-full justify-between font-bold text-text">
                <p>Validity:</p>
                <p className="text-lg"> {data?.validity}</p>
              </div>
              <button
                onClick={payHandler}
                className="h-10 w-80 rounded-lg bg-primary-100 text-black disabled:cursor-not-allowed disabled:bg-opacity-20"
              >
                {loading ? "Loading..." : "Subscribe"}
              </button>
            </div>
          </div>
        </Modal>
      )}

      <div className=" rounded-t-md bg-gradient-to-r from-bg2  to-black px-10 py-5 text-center text-sm font-semibold uppercase text-white  ">
        {data?.title}
      </div>
      <div className="bg-white">
        <div className="flex flex-col gap-3 px-4 pb-2 pt-4 ">
          <CardItem title="Price" value={data?.price} />
          <CardItem title="Validity" value={data?.validity + " " + "days"} />
          <CardItem title="Movies" value={data?.moviesCount} />
          <CardItem title="Series" value={data?.seriesCount} />
        </div>
        <div className="flex flex-row items-center justify-center pb-4  pt-2">
          <button
            onClick={user?.token ? () => setEdit(true) : () => router("/login")}
            className="rounded-md bg-amber-500 px-4 py-2 font-semibold text-white hover:bg-opacity-80"
          >
            {loading ? "Loading..." : "Subscribe"}
          </button>
        </div>
      </div>
    </div>
  );
};

const CardItem = ({ title, value }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-3">
      <p className="text-sm font-[600]">{title}</p>
      <p className="font-[600]  text-black"> {value} </p>
    </div>
  );
};
