import React from "react";
import Static, { Item } from "../../components/commen/Static";

const Terms = () => {
  return (
    <Static title={"Terms & Conditions"}>
      <Item title="">
        This Privacy Policy (“Privacy Policy”) applies to Tulsi App platforms
        including without limitation https://www.tulsiapp.com (
        https://tulsiapp.com) and other related Site/s or App/s, mobile
        applications and other online features each a “Site/s or App/s”).
        <br />
        <br />
        This Privacy Policy should be read in conjunction with the Terms of Use
        available on the “Site/s or App/s”. Personal Information/Data defined
        below of a user/s is collected if the user/s registers with the Site/s
        or App/s, accesses the Site/s or App/s or takes any action on the Site/s
        or App/s or uses or accesses the Services as defined in the Terms of
        Use. The terms ‘Personal Information’ and ‘Sensitive Personal
        Information or Information’ shall have the meaning ascribed to it under
        the Indian Information Technology Act, 2000 read with the Information
        Technology.
        <br />
        <br />
        WHAT DO WE DO WITH YOUR INFORMATION?
        <br />
        <br />
        When you subscribe to something from our packages as part of the buying
        and selling process, we collect the personal information you give us
        such as your name, address and email address.
        <br />
        <br />
        When you browse our packages, we also automatically receive your
        computer’s internet protocol (IP) address in order to provide us with
        information that helps us learn about your browser and operating system.
        Email marketing (if applicable): With your permission, we may send you
        emails about our store, new products and other updates.
        <br />
        <br />
        CONSENT
        <br />
        <br />
        When you provide us with personal information to complete a transaction,
        verify your credit card, we imply that you consent to our collecting it
        and using it for that specific reason only.
        <br />
        <br />
        If we ask for your personal information for a secondary reason, like
        marketing, we will either ask you directly for your expressed consent,
        or provide you with an opportunity to say no.
        <br />
        <br />
        HOW DO I WITHDRAW MY CONSENT?
        <br />
        <br />
        If after you opt-in, you change your mind, you may withdraw your consent
        for us to contact you, for the continued collection, use or disclosure
        of your information, at anytime, by emailing us at support@tulsiapp.com
        or contacting us at the address provided on the website.
        <br />
        <br />
        DISCLOSURE
        <br />
        <br />
        We may disclose your personal information if we are required by law to
        do so or if you violate our Terms of Service.
        <br />
        <br />
        PAYMENT
        <br />
        <br />
        We use TrakNPay for processing payments. We/ TrakNPay do not store your
        card data on their servers. The data is encrypted through the Payment
        Card Industry Data Security Standard (PCI-DSS) when processing payment.
        Your purchase transaction data is only used as long as is necessary to
        complete your purchase transaction.
        <br />
        <br />
        After that is complete, your purchase transaction information is not
        saved. Our payment gateway adheres to the standards set by PCI-DSS as
        managed by the PCI Security Standards Council, which is a joint effort
        of brands like Visa, MasterCard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of credit card information
        by our store and its service providers. For more insight, you may also
        want to read terms and conditions of TrakNPay on https://traknpay.com
        <br />
        <br />
        THIRD-PARTY SERVICES
        <br />
        <br />
        In general, the third-party providers used by us will only collect, use
        and disclose your information to the extent necessary to allow them to
        perform the services they provide to us.
        <br />
        <br />
        However, certain third-party service providers, such as payment gateways
        and other payment transaction processors, have their own privacy
        policies in respect to the information we are required to provide to
        them for your purchase-related transactions.
        <br />
        <br />
        For these providers, we recommend that you read their privacy policies
        so you can understand the manner in which your personal information will
        be handled by these providers.
        <br />
        <br />
        In particular, remember that certain providers may be located in or have
        facilities that are located at different jurisdiction than either you or
        us. So if you elect to proceed with a transaction that involves the
        services of a third- party service provider, then your information may
        become subject to the laws of the jurisdiction(s) in which that service
        provider or its facilities are located.
        <br />
        <br />
        Once you leave our store’s website or are redirected to a third-party
        website or application, you are no longer governed by this Privacy
        Policy or our website’s Terms of Service. our website’s Terms of
        Service.
        <br />
        <br />
        LINKS
        <br />
        <br />
        When you click on links on our website for subscription, they may direct
        you away from our site. We are not responsible for the privacy practices
        of other sites and encourage you to read their privacy statements.
        <br />
        <br />
        SECURITY
        <br />
        <br />
        To protect your personal information, we take reasonable precautions and
        follow industry best practices to make sure it is not inappropriately
        lost, misused, accessed, disclosed, altered or destroyed.
        <br />
        <br />
        COOKIES
        <br />
        <br />
        We use cookies to maintain session of user/s. It is not used to
        personally identify you on other websites.
        <br />
        <br />
        AGE OF CONSENT
        <br />
        <br />
        By using this site, you represent that you are at least the age of
        majority in your state or province of residence, or that you are the age
        of majority in your state or province of residence and you have given us
        your consent to allow any of your minor dependents to use this site.
        <br />
        <br />
        CHANGES TO THIS PRIVACY POLICY
        <br />
        <br />
        We reserve the right to modify this privacy policy at any time, so
        please review it frequently. Changes and clarifications will take effect
        immediately upon their posting on the website. If we make material
        changes to this policy, we will notify you here that it has been
        updated, so that you are aware of what information we collect, how we
        use it, and under what circumstances, if any, we use and/or disclose it.
        If our APP is acquired or merged with another company, your information
        may be transferred to the new owners so that we may continue to provide
        services to you.
        <br />
        <br />
        QUESTIONS AND CONTACT INFORMATION
        <br />
        <br />
        If you would like to: access, correct, amend or delete any personal
        information we have, register a complaint, or simply want more
        information, mail our Privacy Compliance Officer at support@tulsiapp.com
        Or by contacting at the company contact which is hereby incorporated by
        this reference into the Terms of Service. the Terms of Service.
        <br />
        <br />
        TERMS AND CONDITIONS
        <br />
        <br />
        AGE OF MAJORITY
        <br />
        <br />
        Unless otherwise specified, Tulsi App is available for individuals who
        have attained the age of majority in their jurisdiction. In the case of
        Indian jurisdiction, this service is restricted to Users below the age
        of 18 also referred to as minors. If You are under the relevant age of
        majority in Your jurisdiction, You may only access the Website/
        Application and avail the Services only in accordance with applicable
        law which enables a minor in your jurisdiction to access such a service.
        In India, by browsing or downloading and/or installing and/or using
        Tulsi App, You represent and warrant that YOU are 18 years of age or
        older and in case YOU are less than 18 years of age, You have taken
        consent of Your parent or guardian. SOME CONTENT OFFERED ON THE SITE MAY
        NOT BE SUITABLE FOR SOME VIEWERS AND THEREFORE VIEWER'S DISCRETION IS
        ADVISED. ALSO, SOME CONTENT OFFERED ON THE SITE MAY NOT BE APPROPRIATE
        FOR VIEWERSHIP BY MINORS. PARENTS AND/OR LEGAL GUARDIANS ARE ADVISED TO
        EXERCISE DISCRETION BEFORE ALLOWING THEIR CHILDREN AND/OR WARDS TO
        ACCESS CONTENT ON THIS WEBSITE.
        <br />
        <br />
        OWNERSHIP
        <br />
        <br />
        The content on Tulsi App and all copyrights, patents, trademarks,
        service marks, trade names and all other intellectual property rights
        therein are owned by Kamalashree Films Private Limited PVT LTD. and/or
        its licensors or group companies and are protected by applicable Indian
        and international copyright and other intellectual property laws. You
        acknowledge, understand and agree that you shall not have, nor be
        entitled to claim, any rights in and to the Website/ Application
        content/ services and/or any portion thereof. You agree not to copy,
        reproduce, duplicate, stream, capture, archive, upload, download,
        publish, broadcast, sell, resell, edit, modify, manipulate, translate,
        decompile, disassemble, reverse engineer or exploit for any purposes the
        content or any portion of Website/ Application, including, without
        limitation, the Content and the Marks, except as authorized by these
        TERMS or as otherwise authorized in writing by Tulsi App. In addition,
        You are strictly prohibited from creating derivative works, or materials
        that otherwise are derived from or based on in any way the Content and
        the Marks, including montages, mash-ups and similar videos, wallpaper,
        desktop themes, greeting cards, and merchandise, except as authorized by
        these TERMS or as otherwise authorized in writing by Tulsi App. You must
        abide by all copyright notices, information, and restrictions contained
        in or associated with any Content. You must not remove, alter, interfere
        with, or circumvent any copyright, trademark, or other proprietary
        notices marked on the Content or any digital rights management
        mechanism, device or other content protection or access control measure
        (including, without limitation, Geo-filtering and/or encryption)
        associated with the Content. You hereby agree that all intellectual
        property rights, title and interest in the user-generated content
        published or generated on Website/ Application by You shall vest with
        Tulsi App.
        <br />
        <br />
        YOUR RESPONSIBILITIES
        <br />
        <br />
        You will be solely responsible for obtaining and maintaining the Device
        and Internet connection needed in order to access and use Tulsi App and
        paying for all such charges in relation thereto. Internet charges will
        depend on the plan subscribed by You from the internet service provider.
        Tulsi App is compatible on selected operating systems and specific
        versions and Device(s). The download procedure of the Application shall
        be subject to the process specified by the operating system of Your
        Device(s). You need to have a Device connected with Internet for
        download of Tulsi App Application to begin and complete.Tulsi App shall
        not be responsible in case of any fluctuation in the Internet connection
        speed leading to corruption of Application file download or any delayed
        or defective download of the Application on Your Device(s). Tulsi App
        shall not be responsible or liable to you for interruption, disruption,
        deactivation of Tulsi App on account of any Force Majeure Event. For the
        purpose of these Terms of Use, “Force Majeure Event” shall mean any
        event beyond the reasonable control of Tulsi App including but not
        limited to act of God, any act or omission of government or
        quasi-government agencies or lockout, strike, curfew, technical errors
        etc. Tulsi App may, at its sole discretion, make bug fixes, updates for
        the installed Application. In the event Tulsi App has upgraded the
        Application or any features thereof, you will be required to update Your
        Device in order to make the Device compatible with such upgrades. Tulsi
        App shall not be responsible or liable to You in the event You are
        unable to access Tulsi App or view the Content on Tulsi App due to Your
        failure to upgrade Your Device.
        <br />
        <br />
        You further agree not to use our packages for any illegal or
        unauthorized purpose nor may you, in the use of the Service, violate any
        laws in your jurisdiction including but not limited to copyright laws.
        You must not transmit any worms or viruses or any code of a destructive
        nature. A breach or violation of any of the Terms will result in
        immediate termination of your Services.
        <br />
        <br />
        You understand that your contents - not including credit card
        information, may be transferred unencrypted and involve
        <br />
        <br />
        Transmissions over various networks; and
        <br />
        <br />
        changes to conform and adapt to technical requirements of connecting
        networks or devices. Credit card information is always encrypted during
        transfer over networks.
        <br />
        <br />
        REGISTRATION
        <br />
        <br />
        To register for the Tulsi App Website/ Application, You shall be
        required to open an account by completing the registration process by
        providing us with your current, complete and accurate information as
        prompted by the applicable registration form; You shall also choose a
        password and a user name. Tulsi App reserves the right to suspend or
        terminate Your registration as Registered User without assigning any
        reason (including for provision of inaccurate, not current or incomplete
        information during the registration process or thereafter). As a
        Registered User, You shall be responsible for safeguarding Your password
        and for all transactions undertaken using Your Username and password.
        You agree not to disclose Your password to any third party and to take
        sole responsibility for any activities or actions under Your account,
        whether or not You have authorized such activities or actions. In
        particular, as a parent or legal guardian, you acknowledge and assume
        sole responsibility to ensure that content which is meant for mature
        audiences, i.e., above the age of majority, is not accessed by children.
        Hence, you may not share your log in credentials with your children. It
        is Your sole responsibility to change Your password immediately if You
        believe that Your password has been compromised. Tulsi App will not be
        responsible for any financial loss, inconvenience or mental agony
        resulting from misuse of Your Username and password in any
        circumstances. You expressly agree to absolve Tulsi App and/or the Tulsi
        App Website/ Application of any responsibility/ liability in this
        regard.
        <br />
        <br />
        We reserve the right to refuse service to anyone for any reason at any
        time.
        <br />
        <br />
        ACCURACY OF BILLING AND ACCOUNT INFORMATION
        <br />
        <br />
        By subscribing to Tulsi App, you are expressly agreeing that Tulsi App
        is authorized to charge you subscription fee as per your selected plan,
        any other fees for additional services you may purchase, and any
        applicable taxes in connection with your use of Tulsi App’s Service
        through any such payment method/ instrument including but not limited to
        credit/debit card, net banking or other payment method/ instrument
        accepted by Tulsi App ("Payment Method") that you are provided. The
        Payment Methods will differ from platform to platform (operating
        systems). In the event your subscription expires, Tulsi App reserves the
        right to auto-renew your subscription through your authorized Payment
        Method provided such Payment Method offers the service of auto-renewal.
        You hereby authorize Us to effect such auto-renewal in the
        above-mentioned scenario. In the event you desire to discontinue your
        auto-renewed subscription, you may contact us at support@tulsiapp.com in
        order to obtain assistance in this regard. All fees and charges are
        non-refundable and there are no refunds or credits for partially used
        periods and/or unwatched content.
        <br />
        <br />
        You agree to provide current, complete and accurate account information
        for all subscriptions. You agree to promptly update your account and
        other information, including your email address and payment information,
        so that we can complete your transactions and contact you as needed.
        <br />
        <br />
        SUBSCRIPTION
        <br />
        <br />
        To be able to view the content (Except for Trailers), you will be
        required to subscribe to any one subscription plan (“Plan”) on Tulsi App
        Website/ Application. Any Registered User can avail the Subscription
        Package ("Subscription User"). As Subscription User, You will be
        required to subscribe to Subscription Packages made available by Tulsi
        App from time to time. The subscription to Subscription Packages may be
        subject to additional terms specified by Tulsi App. Depending on
        packages and/or value added services and the term of the proposed
        subscription, you will be required to make payment via a Payment Method
        provided by Tulsi App for accessing and browsing Tulsi App. If You wish
        to avail Tulsi App after the expiry of the initial term of Subscribed
        Packages, You shall prior to the expiry of such term, recharge Your
        account with the then prevailing subscription charges.
        <br />
        <br />
        Tulsi App reserves the right to modify or discontinue Website/
        Application at its sole discretion with or without notice to You. Tulsi
        App shall not be liable to You or any third party in any manner, should
        Tulsi App exercise its right to modify or discontinue Website/
        Application. Further, Tulsi App reserves the right, at any time, with or
        without notice and without any liability to:
        <br />
        <br />
        Replace or otherwise withdraw Subscription Packages or Content,
        <br />
        <br />
        change or reduce the number of hours of any Content; blackout any
        Content; and
        <br />
        <br />
        modify the prices for Subscription Packages or any part of Tulsi App
        service. Further, Tulsi App reserves the right to change packaging and
        introduce base and add on packages and/or offer content on individual
        basis. You acknowledge and agree that the Content provided by Tulsi App
        is being supplied by third parties and availability of such Content is
        outside Tulsi App's control and can be removed anytime. Additionally,
        Tulsi App shall have the right, but not the obligation, to refrain from
        providing to You any Content in the interest of national security or in
        the event of emergency/ war or similar situation or if the Content is
        anti-national, promotes political/ religious propaganda, is against
        public policy, is banned or restricted from being distributed under any
        applicable law or Tulsi App otherwise determine that it is objectionable
        or is derogatory to any person or class of persons, hurts the religious
        sentiments of any religious group or infringes the privacy rights of any
        individual(s) or is not in the interest of Tulsi App’s subscribers or
        the general public.
        <br />
        <br />
        In a credit card and/or debit card and/or net banking and/or cash card
        transaction and/or any other mode available, You must use Your own
        credit card and/or debit card and/or net banking account and/or cash
        card. You confirm and acknowledge that You are aware of the fact that
        when making any online payment through credit card or debit card or net
        banking account or cash card or via any other mode available, You may be
        directed to an external payment gateway page. The payment gateway may
        redirect
        <br />
        <br />
        You to other website(s) maintained or controlled by third parties, and
        Tulsi App does not control such third party website(s) and hence is not
        responsible for any transactions on such website(s). Tulsi App will not
        be liable for any credit card or debit card or net banking or cash card
        fraud, and Tulsi App will not entertain or address any such grievances
        or issues. You are requested to communicate all grievances related to
        such issues to Your bank or mobile carrier/operator or mobile wallet
        provider who has issued such credit card or debit card or net banking
        account or cash card. Tulsi App shall not be responsible for all or any
        dispute or difference relating to online payment made by You through
        credit card or debit card or net banking account or cash card or via any
        other mode available. Further, Tulsi App will not be responsible for any
        financial loss, inconvenience or mental agony resulting from misuse of
        Your credit card or debit card or net banking account number or cash
        card and other details. Payments once made by credit card or debit card
        or net banking or cash cards or via any other mode available shall not
        be refunded in any circumstances. You are hereby advised to keep details
        of your credit/debit card and net banking confidential and do not share
        any such details with any other third party. Tulsi App is available in
        select countries only. Tulsi App would not be held responsible for
        hindrances in the access and use of Tulsi App due to geographical change
        in Your location. Further, YOU will be solely liable for accessing and
        using Tulsi App in the countries of Your use. Your subscription to Tulsi
        App in a particular geographical territory shall be valid for that
        territory only and shall not automatically entitle you to access your
        account from a different geographical territory, unless specifically
        permitted by Tulsi App. You will be required to re-subscribe to Tulsi
        App in the event that you wish to use the services from a different
        territory other than the one from where you Originalsly subscribed to
        Tulsi App.
        <br />
        <br />
        Tulsi App shall have the discretion to make certain or all Content that
        is a part of the Subscription available to You on either one or limited
        number of end user device concurrently.
        <br />
        <br />
        ACCESS TO USE
        <br />
        <br />
        To access the Tulsi App Website/ Application, You will have to enter
        Your individual Username and password, as chosen by you during your
        registration.
        <br />
        <br />
        If Tulsi App reasonably believes that an account and password is being
        misused in any manner, Tulsi App reserves the right to cancel access
        rights immediately without notice, and block access to all users from
        that IP address. Furthermore, you shall be entirely responsible for any
        and all activities that occur under your account. You agree to notify
        Tulsi App immediately of any unauthorized use of your account or any
        other breach of security. Tulsi App will not be liable for any loss that
        you may incur as a result of someone else using your password or
        account, however, you could be held liable for losses incurred by Tulsi
        App or another party due to someone else using your account or password.
        <br />
        <br />
        AVAILABILITY & QUALITY
        <br />
        <br />
        The availability of content(s) to view through Website/ Application will
        change from time to time at the sole discretion of Tulsi App. The
        quality of the display of the streaming video may vary from computer to
        computer, and device to device, and may be affected by a variety of
        factors, such as your location, the bandwidth available through and/ or
        speed of your internet connection, and/ or quality of user’s hardware.
        You are responsible for all internet access charges. Please check with
        your internet provider for information on possible internet data usage
        charges. Geographic Limitation: You may watch a video through the Tulsi
        App Website/ Application only in geographical locations where Tulsi App
        offers the content, and the content available to view will vary by
        geographical location determined by Tulsi App. Tulsi App may use
        technologies to verify your geographical location.
        <br />
        <br />
        RESTRICTIONS & PROHIBITED USES
        <br />
        <br />
        It is hereby clarified that Tulsi App is provided for Your personal,
        non- commercial use only. You agree not to, either directly or through
        the use of any device, software, web-based service, or by other means,
        copy, download, archive, perform, display, upload, publish, transmit or
        retransmit the Content or create any work or material that is derived
        from or based on the Content, rent, lease, duplicate, sub-license,
        assign, pledge, loan, or resell the Content of Tulsi App. You shall only
        use Tulsi App on Device(s) that is under Your exclusive control and
        ownership. You shall not permit any third party to benefit from the use
        or functionality of Tulsi App, either directly or via any facility
        management, time-sharing, service bureau or any other arrangement. If
        You transfer possession of any copy of Tulsi App to another party, Your
        subscription of Tulsi App shall automatically get terminated. You shall
        not use any "deep-link", "page-scrape", "robot", "spider" or other
        automatic device(s), program, algorithm or methodology, or any similar
        or equivalent manual process, to access, acquire, copy or monitor any
        portion of Tulsi App or any Content, or in any way reproduce or
        circumvent the navigational structure or presentation of Tulsi App or
        any Content, to obtain or attempt to obtain any materials, documents or
        information through any means not purposely made available through Tulsi
        App. We reserve the right to bar any such activity.
        <br />
        <br />
        You shall not probe, scan or test the vulnerability of Tulsi App website
        or any network connected to Tulsi App nor breach the security or
        authentication measures on Tulsi App or any network connected to Tulsi
        App. You may not reverse look-up, trace or seek to trace any information
        on any other user of or visitor to Tulsi App to its source. You also
        agree that You will not take any action that imposes an unreasonable or
        disproportionately large load on the infrastructure of Tulsi App or any
        systems or networks connected to Tulsi App. You may not pretend that You
        are, or that You represent, someone else, or impersonate any other
        individual or entity. Your correspondence or business dealings with, or
        participation in promotions of, third party advertisers found on or
        through Tulsi App, including payment and delivery of goods or services,
        and any other terms, conditions, warranties or representations
        associated with such dealings, are solely between You and such third
        party advertiser. We shall not be responsible or liable for any loss or
        damage of any sort incurred as the result of any such dealings or as the
        result of the presence of such third party advertisers on
        <br />
        <br />
        You acknowledge and agree that Tulsi App is not responsible or liable
        for:
        <br />
        <br />
        the availability or accuracy of such websites or resources; or
        <br />
        <br />
        the content, products, or services on or available from such websites or
        resources. Links to such websites or resources do not imply any
        endorsement by Tulsi App of such websites or resources or the content,
        products, or services available from such websites or resources. You
        acknowledge sole responsibility for and assume all risks arising from
        Your use of any such websites or resources.
        <br />
        <br />
        It is possible that other users (including unauthorized users or
        "hackers") may post or transmit offensive or obscene materials on Tulsi
        App and that You may be involuntarily exposed to such offensive and
        obscene materials. It also is possible for others to obtain personal
        information about You on the public forum due to Your use of Tulsi App,
        and that the recipient may use such information to harass or injure You.
        We do not approve of such unauthorized uses but by browsing/using Tulsi
        App, You acknowledge and agree that We shall not responsible for the use
        of any personal information that You publicly disclose or share with
        others on Tulsi App.
        <br />
        <br />
        In addition to other prohibitions as set forth in the Terms of Service,
        you are prohibited from using the site or its content:
        <br />
        <br />
        (i)for any unlawful purpose; (ii)to solicit others to perform or
        participate in any unlawful acts; (iii)to violate any international,
        federal, provincial or state regulations, rules, laws, or local
        ordinances; (iv)to infringe upon or violate our intellectual property
        rights or the intellectual property rights of others; (v)to harass,
        abuse, insult, harm, defame, slander, disparage, intimidate, or
        discriminate based on gender, sexual orientation, religion, ethnicity,
        race, age, national origin, or disability; (vi)to submit false or
        misleading information; (vii)to upload or transmit viruses or any other
        type of malicious code that will or may be used in any way that will
        affect the functionality or operation of the Service or of any related
        website, other websites, or the Internet; (viii)to collect or track the
        personal information of others; (xi)to spam, Phish, harm, pretext,
        spider, crawl, or scrape; (x)for any obscene or immoral purpose; or
        (xi)to interfere with or circumvent the security features of the Service
        or any related website, other websites, or the Internet. We reserve the
        right to terminate your use of the Service or any related Website/
        Application for violating any of the prohibited uses.
        <br />
        <br />
        OPTIONAL TOOLS
        <br />
        <br />
        You acknowledge and agree that we provide access to such tools “as is”
        and “as available” without any warranties, representations or conditions
        of
        <br />
        <br />
        any kind and without any endorsement. We shall have no liability
        whatsoever arising from or relating to your use of optional third-party
        tools. Any use by you of optional tools offered through the site is
        entirely at your own risk and discretion and you should ensure that you
        are familiar with and approve of the terms on which tools are provided
        by the relevant third- party provider(s).
        <br />
        <br />
        THIRD-PARTY LINKS
        <br />
        <br />
        You acknowledge and agree that certain content, products and services
        available via our Service may include materials from third-parties.
        Third-party links on this site may direct you to third-party websites
        that are not affiliated with us. We are not responsible for examining or
        evaluating the content or accuracy and we do not warrant and will not
        have any liability or responsibility for any third-party materials or
        websites, or for any other materials, products, or services of
        third-parties. We are not liable for any harm or damages related to the
        subscriptions or use of services, resources, content, or any other
        transactions made in connection with any third-party websites. Please
        review carefully the third- party's policies and practices and make sure
        you understand them before you engage in any transaction. Complaints,
        claims, concerns, or questions regarding third-party products should be
        directed to the third-party.
        <br />
        <br />
        USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
        <br />
        <br />
        If, at our request, you send certain specific submissions (for example
        contest entries) or without a request from us you send creative ideas,
        suggestions, proposals, plans or other materials, whether online, by
        email, by postal mail, or otherwise (collectively, 'comments'), you
        agree that we may, at any time, without restriction, edit, copy,
        publish, distribute, translate and otherwise use in any medium any
        comments that you forward to us. We are and shall be under no obligation
        <br />
        <br />
        to maintain any comments in confidence;
        <br />
        <br />
        to pay compensation for any comments; or (3) to respond to any comments.
        <br />
        <br />
        We may, but have no obligation to, monitor, edit or remove content that
        we determine in our sole discretion are unlawful, offensive,
        threatening, libellous, defamatory, pornographic, obscene or otherwise
        objectionable or violates any party’s intellectual property or these
        Terms of Service. You will not interfere with or disrupt, or attempt to
        interfere with or disrupt, the operation of the Website/ Application or
        any parts thereof. You will not remove, alter, interfere with or
        circumvent any digital rights management mechanism, device or other
        content protection or access control measure including, without
        limitation Geo-filtering and/or encryption, etc., associated with
        Website/ Application content. You agree that your comments will not
        violate any right of any third-party, including copyright, trademark,
        privacy, personality or other personal or proprietary right.
        <br />
        <br />
        You further agree that your comments will not contain defamatory
        otherwise unlawful, abusive or obscene material, or contain any computer
        virus or other malware that could in any way affect the operation of the
        Service or any related website.
        <br />
        <br />
        You may not use a false e-mail address, pretend to be someone other than
        yourself, or otherwise mislead us or third-parties as to the origin of
        any comments. You are solely responsible for any comments you make and
        their accuracy. We take no responsibility and assume no liability for
        any comments posted by you or any third-party.
        <br />
        <br />
        CHANGES TO TERMS OF SERVICE
        <br />
        <br />
        You can review the most current version of the Terms of Service at any
        time at this page. We reserve the right, at our sole discretion, to
        update, change or replace any part of these Terms of Service by posting
        updates and changes to our website. It is your responsibility to check
        our website periodically for changes. Your continued use of or access to
        our website or the Service following the posting of any changes to these
        Terms of Service constitutes acceptance of those changes.
        <br />
        <br />
        PRIVACY POLICY
        <br />
        <br />
        Your submission of personal information through the subscription is
        governed by our Privacy Policy.
        <br />
        <br />
        RELATIONSHIP
        <br />
        <br />
        The relationship between Tulsi App and You is on a
        principal-to-principal basis. You are in no way Tulsi App’s legal
        representative, partner or agent for any reason whatsoever.
        <br />
        <br />
        NOTICES
        <br />
        <br />
        Any notices or other communications required will be in writing and
        emailed to Tulsi App at support@tulsiapp.com for notices made by e-mail,
        the date of receipt will be deemed the date on which such notice is
        transmitted. In the event you have any complaints with respect to any
        Content on Tulsi App, please write to us at support@tulsiapp.com with
        details of the objectionable content and Your details including Your
        name and mobile number and such other details as may be requested by Us.
        Based on the complaint raised, we will take reasonable measures to
        resolve the issue. You hereby expressly agree that Tulsi App shall under
        no circumstance be liable to You for any damages whatsoever. Further,
        You hereby expressly agree that Tulsi App makes no representations under
        these Terms of Use that any complaint raised by You will be resolved to
        Your satisfaction. All steps to be taken by Tulsi App in this regard
        shall be at the sole discretion of Tulsi App.
        <br />
        <br />
        TERMINATION
        <br />
        <br />
        You may terminate these Terms of Service at any time by notifying us
        that You no longer wish to use our Services, or when you cease using our
        site. As Subscription User, Your right to use Tulsi App shall
        automatically terminate on the expiry of the term of the Subscription
        Package. You may discontinue your participation in and access to Tulsi
        App at any time. You agreed that upon suspension or termination, no
        amount shall be refunded to You by Tulsi App.
        <br />
        <br />
        Your right to use Tulsi App shall automatically terminate if you violate
        these Terms of Use or any terms, rules or guidelines published in
        connection with Tulsi App. Tulsi App reserves the right, in its sole
        discretion, to suspend or terminate Your access to all or any part of
        Tulsi App, for any reason, with or without notice.
        <br />
        <br />
        COPYRIGHT INFRINGEMENT NOTIFICATION
        <br />
        <br />
        Tulsi App respects the intellectual property rights of others. Upon
        proper notice, Tulsi App will remove User Submissions (and any other
        Website/ Application content) that violates copyright law. As per the
        provisions of Information Technology Act, 2000 and any amendments made
        thereto along with the rules framed there under and the provisions of
        the Copyright Act, 1957 along with all the amendments made thereto and
        rules framed there under, Tulsi App shall remove upon receiving written
        notification of claimed copyright infringement on the Website/
        Application and for processing such claims in accordance with such law.
        If you believe a work has been copied in a way that constitutes
        copyright infringement, please send a notification of claimed
        infringement (an "Infringement Notification") that contains all the
        following information: (i) identification of the copyrighted work
        claimed to have been infringed; (ii) identification of the claimed
        infringing material and information reasonably sufficient to permit
        Tulsi App to locate the material on Website/ Application; (iii)
        information reasonably sufficient to permit Tulsi App to contact You,
        such as an address, telephone number, and, if available, an e-mail
        address; (iv) a statement by You that you have a good faith belief that
        the disputed use is not authorized by the copyright owner, its agent, or
        the law; (v) a statement by You, made under penalty of perjury, that the
        above information in your notification is accurate and that You are the
        copyright owner or authorized to act on the copyright owner's behalf;
        and (vi) Your physical signature. By submitting an Infringement
        Notification, You acknowledge and agree that Tulsi App may forward your
        Infringement Notification and any related communications to any users
        who posted the material identified in such notice.
        <br />
        <br />
        INFRINGEMENT NOTIFICATION IS TO BE SENT TO
        <br />
        {/* <br />
        Tulsi App – Kamalashree Films Private Limited App, 222, Ijmima complex
        Malad West 400064.
        <br /> */}
        <br />
        Tulsi App – support@tulsiapp.com
        <br />
        <br />
        ERRORS, INACCURACIES AND OMISSIONS
        <br />
        <br />
        Occasionally there may be information on our site or in the Service that
        contains typographical errors, inaccuracies or omissions that may relate
        to product descriptions, pricing, promotions, offers, availability etc.
        We reserve the right to correct any errors, inaccuracies or omissions,
        and to change or update information or cancel subscription(s) if any
        information in the Service or on any related website is inaccurate at
        any time without prior notice, including after you have subscribed the
        packages. We undertake no obligation to update, amend or clarify
        information in the Service or on any related website, including without
        limitation, pricing information, except as required by law.
        <br />
        <br />
        DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY YOU EXPRESSLY AGREE
        THAT YOUR USE OF THE WEBSITE/ APPLICATION IS AT YOUR SOLE RISK. THE
        WEBSITE/ APPLICATION IS PROVIDED "AS IS" "WITH ALL FAULTS" AND "AS
        AVAILABLE." TO THE FULL EXTENT PERMITTED BY LAW, Tulsi App AND ITS
        AFFILIATES EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND,
        WHETHER EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE
        AND NON- INFRINGEMENT). Neither Tulsi App nor any of its affiliates make
        any warranties or representations about the accuracy or completeness of
        content available on or through the Website/ Application or the content
        of any web sites, Personal Social Media or other internet or mobile
        resources linked to the Website/ Application and assume no liability or
        responsibility for any errors, mistakes, or inaccuracies of content. In
        no case shall Tulsi App, our directors, officers, employees, affiliates,
        agents, contractors, interns, suppliers, service providers or licensors
        be liable for any injury, loss, claim, or any direct, indirect,
        incidental, punitive, special, or consequential damages of any kind,
        including, without limitation lost profits, lost revenue, lost savings,
        loss of data, replacement costs, or any similar damages, whether based
        in contract, tort (including negligence), strict liability or otherwise,
        arising from your use of any of the service or any packages procured
        using the service, or for any other claim related in any way to your use
        of the service or any package, including, but not limited to, any errors
        or omissions in any content, or any loss or damage of any kind incurred
        as a result of the use of the service or any content posted,
        transmitted, or otherwise made available via the service, even if
        advised of their possibility. Because some states or jurisdictions do
        not allow the exclusion or the limitation of liability for consequential
        or incidental damages, in such states or jurisdictions, our liability
        shall be limited to the maximum extent permitted by law.
        <br />
        <br />
        We do not guarantee, represent or warrant that your use of our service
        will be uninterrupted, timely, secure or error-free. We do not warrant
        that the results that may be obtained from the use of the service will
        be accurate or reliable. You agree that from time to time we may remove
        the service for indefinite periods of time or cancel the service at any
        time, without notice to you.
        <br />
        <br />
        INDEMNIFICATION
        <br />
        <br />
        You agree to indentify, defend, and hold harmless, Tulsi App, its
        parent, subsidiaries, affiliates, partners, officers, directors,
        consultants, agents, licensors, contractors, sub contractors, service
        providers, suppliers, interns and employees (“indemnified parties”) from
        and against any and all claims, liabilities, damages, losses, costs,
        expenses, fees (including reasonable attorneys' fees and costs) that
        such indemnified parties may incur as a result of or arising from (a)
        any information (including, without limitation, your user generated
        content, feedback, or any other content) you (or anyone using your
        account) submit, post, or transmit on or through Tulsi App; (b) your (or
        anyone using your account's) use of Tulsi App; (c) your (or anyone using
        your account's) violation of these terms; or (d) your (or
        <br />
        <br />
        anyone using your account's) violation of any rights of any other person
        or entity, including, without limitation, any copyright, patent,
        trademark, trade secret or other proprietary rights of any person or
        entity. Tulsi App reserves the right, at its own expense, to assume the
        exclusive defense and control of any matter otherwise subject to
        indemnification by you, in which event you will cooperate with Tulsi App
        in asserting any available defenses. this provision shall remain in full
        force and effect notwithstanding any termination of your use of the
        site.
        <br />
        <br />
        SEVERABILITY
        <br />
        <br />
        In the event that any provision of these Terms of Service is determined
        to be unlawful, void or unenforceable, such provision shall nonetheless
        be enforceable to the fullest extent permitted by applicable law, and
        unenforceable portion shall be deemed to be severed from these Terms of
        Service, such determination shall not affect validity and enforceability
        of any other remaining provisions.
        <br />
        <br />
        GOVERNING LAW
        <br />
        <br />
        These Terms of Service and any separate agreements whereby we provide
        you Services shall be governed by and construed in accordance with the
        laws of India and jurisdiction of Mumbai, Maharashtra.
        <br />
        <br />
        ENTIRE AGREEMENT
        <br />
        <br />
        The failure of us to exercise or enforce any right or provision of these
        Terms of Service shall not constitute a waiver of such right or
        provision. These Terms of Service and any policies or operating rules
        posted by us on this site or in respect to the service constitutes the
        entire agreement and understanding between You and Tulsi App and govern
        Your use of the Service, superseding any prior or contemporaneous
        agreements, communications and proposals, whether oral or written,
        between You and
        <br />
        <br />
        Tulsi App (including, but not limited to, any prior versions of the
        Terms of Service). Any ambiguities in the interpretation of these Terms
        of Service shall not be construed against the drafting party. The
        headings used in this agreement are included for convenience only and
        will not limit or otherwise affect these Terms.
        <br />
        <br />
        CONTACT INFORMATION
        <br />
        <br />
        Questions about the Terms of Service should be sent to us at
        support@tulsiapp.com
      </Item>
    </Static>
  );
};

export default Terms;
