import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const links = [
    {
      title: "About",
      path: "/about-us",
    },
    {
      title: "Terms of Service",
      path: "/termsandconditions",
    },
    {
      title: "Privacy Policy",
      path: "/privacyPolicy",
    },

    {
      title: "Refund Policy",
      path: "/refund-policy",
    },
    // {
    //   title: "Return Policy",
    //   path: "/return-policy",
    // },
    // {
    //   title: "Shipping Policy",
    //   path: "/shipping-policy",
    // },
  ];
  return (
    <div className="flex flex-col justify-between gap-6 bg-secondary3  px-10 py-6 md:flex-row md:items-center">
      <div className="flex flex-col gap-6 md:flex-row">
        {links.map((link, index) => (
          <div
            onClick={() => navigate(link.path)}
            key={index}
            className="cursor-pointer text-[13px]  text-white hover:text-white"
          >
            {link.title}
          </div>
        ))}
      </div>
      {/* <div className="flex gap-6">
        <Link
          to="https://play.google.com/store/apps/details?id=com.alt.alt_premiere"
          target="_blank"
        >
          <img src={img1} alt="appstore" className="h-10 md:h-8" />
        </Link>
      </div> */}

      {/* <Link
        to="https://drive.google.com/file/d/1_s9kbFYtqBP1Egw8CmJrIzVVuiwxo-ja/view?usp=sharing"
        target="_blank"
        className="fixed bottom-5 right-5 z-20 px-4 py-2 flex h-30 w-30 cursor-pointer items-center justify-center rounded-full bg-primary-100  text-[13px]  text-white hover:text-gray-500"
      >
        <div className="text-base">Download App</div>
      </Link> */}
    </div>
  );
};

export default Footer;
