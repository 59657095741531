// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCu_q8GPiplq9e664ajsyocBpaXszN1voY",
  authDomain: "tulsi-4b5cb.firebaseapp.com",
  projectId: "tulsi-4b5cb",
  storageBucket: "tulsi-4b5cb.appspot.com",
  messagingSenderId: "10573236050",
  appId: "1:10573236050:web:8811bb8de3473ca2e8dc8b",
  measurementId: "G-WKW40JPJ8F",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
