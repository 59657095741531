import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React from "react";
import { BiChevronRight } from "react-icons/bi";
import Input from "../../components/commen/Input";
import Loading from "../../components/commen/Loading";
import Loading2 from "../../components/commen/Loading2";
import Modal from "../../components/commen/Modal";
import useValidate from "../../hooks/useValidate";
import { getProfile, updateProfilePic, updateUserData } from "../../utils/apis";
import { auth } from "../../utils/firebaseconfig";
import useStore from "../../utils/store";

const Account = () => {
  const user = useStore((state) => state.user);

  const updateUser = useStore((state) => state.updateUser);
  const [Edit, setEdit] = React.useState(false);
  const [newData, setNewData] = React.useState({
    name: user?.user?.name,
    email: user?.user?.email,
    phoneNumber: user?.user?.phoneNumber,
    dob: user?.user?.dob,
  });

  const [profile, setProfile] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [loading3, setLoading3] = React.useState(false);
  const [otpSent, setOtpSent] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [error, setError] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const getProfileHandler = async (update) => {
    setLoading2(true);
    const [success, response] = await getProfile(user.token);

    if (success) {
      setProfile(response);
      update &&
        updateUser({
          user: response,
          token: user.token,
          loginWith: user.loginWith,
        });
    }
    setLoading2(false);
  };

  const onChangeData = (e, name) => {
    setNewData((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);
    const [success, response] = await updateProfilePic(user.token, formData);
    if (success) {
      getProfileHandler(true);
    }
    setLoading(false);
  };

  const ChangeUserData = async () => {
    // if (newData.phoneNumber !== user?.user?.phoneNumber && !disabled) {
    //   return;
    // }

    setLoading2(true);
    const [success, response] = await updateUserData(user.token, newData);
    if (success) {
      getProfileHandler(true);
      setEdit(false);
    } else {
      setError(response.message);
    }
    setLoading2(false);
  };

  const SendOtp = async () => {
    setLoading3(true);

    if (newData.phoneNumber.length !== 13) {
      setLoading3(false);
      return;
    }
    try {
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        auth
      );

      const confirmationResult = await signInWithPhoneNumber(
        auth,
        newData.phoneNumber,
        recaptchaVerifier
      );
      window.confirmationResult = confirmationResult;
      setOtpSent(true);
    } catch (err) {}
    setLoading3(false);
  };

  const verifyOtp = async () => {
    setLoading3(true);
    try {
      if (otp.length !== 6) {
        setLoading(false);
        return;
      }
      await window.confirmationResult.confirm(otp);

      setDisabled(true);
    } catch (err) {}
    setLoading3(false);
  };

  const [data, navigate] = useValidate(getProfileHandler);

  console.log(user);
  if (loading2) {
    return <Loading />;
  }
  return (
    <div className="flex flex-1 flex-col items-center justify-center py-10">
      <div className="flex flex-col items-center gap-6">
        <div className="group flex flex-col items-center justify-center">
          {loading ? (
            <Loading2 />
          ) : (
            <div
              className="relative cursor-pointer"
              onClick={() => {
                document.getElementById("file").click();
              }}
            >
              <img
                src={profile?.image}
                alt="profile"
                className="h-20 w-20 rounded-full bg-white"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 text-white opacity-0 transition-all group-hover:bg-opacity-40 group-hover:opacity-100">
                Edit
              </div>
            </div>
          )}
          <h1 className=" mt-2 text-center text-xl font-bold text-white">
            {profile?.name}
          </h1>
          <p className="text-center text-sm text-text">
            {user?.loginWith === "phone"
              ? profile?.phoneNumber
              : profile?.email}
          </p>
          <p className="text-center text-sm text-text">{profile?.dob}</p>
        </div>
        <div className="flex flex-col gap-2">
          <Button
            title="Edit Profile"
            onClick={() => {
              setEdit(true);
            }}
          />
          <Button
            title="Support"
            onClick={() => {
              window.open("mailto:ayyodigital@gmail.com", "_blank");
            }}
          />
          <Button
            title="Subscriptions History"
            onClick={() => {
              navigate("/my-account/subscriptions-history");
            }}
          />
        </div>
      </div>
      <input
        type="file"
        id="file"
        className="hidden"
        onChange={uploadFileHandler}
      />
      {Edit && (
        <Modal
          onClose={() => {
            setEdit(false);
          }}
          height="h-auto"
        >
          <div className="flex h-full flex-col items-center justify-center rounded bg-secondary3 py-5">
            {error && (
              <p className="text-center text-sm text-red-500">{error}</p>
            )}
            <h1 className="mb-2 text-2xl font-bold text-text">Edit Profile</h1>
            <div className="flex flex-col items-center justify-center gap-4">
              <Input
                type="text"
                placeholder="Name"
                value={newData.name}
                onChange={(e) => {
                  onChangeData(e, "name");
                }}
              />
              {/* {user.loginWith === "email" && (
                <>
                  <Input
                    type="text"
                    placeholder="Phone Number"
                    value={newData.phoneNumber}
                    onChange={(e) => {
                      onChangeData(e, "phoneNumber");
                    }}
                  />
                  {profile?.phoneNumber !== newData.phoneNumber && (
                    <button
                      className="flex h-10  w-80 items-center justify-center rounded-lg bg-primary-100 text-white disabled:cursor-not-allowed disabled:bg-opacity-20"
                      disabled={otpSent}
                      onClick={SendOtp}
                    >
                      {loading3 ? <Loading2 /> : "Send OTP"}
                    </button>
                  )}
                  {otpSent && (
                    <>
                      <OtpInput
                        numInputs={6}
                        value={otp}
                        onChange={setOtp}
                        separator={<span className="w-1.5"></span>}
                        inputStyle="flex-1 h-10 text-center text-text bg-secondary3  border-text border px-3 py-2.5 rounded outline-none"
                        containerStyle="otpinput w-full "
                      />
                      <button
                        disabled={disabled}
                        onClick={verifyOtp}
                        className="flex h-10 w-80 items-center justify-center rounded-lg bg-primary-100 text-white disabled:cursor-not-allowed disabled:bg-opacity-20"
                      >
                        {loading3 ? <Loading2 /> : "Verify OTP"}
                      </button>
                    </>
                  )}
                </>
              )}
              {user.loginWith === "phone" && (
                <Input
                  type="text"
                  placeholder="Email"
                  value={newData.email}
                  onChange={(e) => {
                    onChangeData(e, "email");
                  }}
                />
              )} */}
              {user.loginWith === "phone" && (
                <Input
                  type="text"
                  placeholder="Email"
                  value={newData.email}
                  onChange={(e) => {
                    onChangeData(e, "email");
                  }}
                />
              )}
              <button
                onClick={ChangeUserData}
                className="h-10 w-80 rounded-lg bg-primary-100 text-black disabled:cursor-not-allowed disabled:bg-opacity-20"
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* <div id="recaptcha-container" className="hidden"></div> */}
    </div>
  );
};

export default Account;

const Button = ({ title, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex w-80 items-center justify-between bg-primary-100 bg-opacity-20 px-5 py-5 text-white"
    >
      {title}
      <BiChevronRight className="text-xl" />
    </button>
  );
};
