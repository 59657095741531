import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const VideoCard = ({ data, episode, onClick }) => {
  const navigate = useNavigate();
  const swiperRef = React.useRef(null);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);
  return (
    <div className="mx-2 md:mx-5">
      <div className="flex items-center justify-between">
        <h1 className="pl-2 text-lg font-semibold text-white">{data?.name}</h1>

        <button
          onClick={() => {
            navigate(`/view-all/${data?.name}`, { state: data });
          }}
          className="flex items-center gap-2 px-2 py-1 text-xs font-semibold text-white transition-all "
        >
          View All <FaAngleRight />
        </button>
      </div>
      <Swiper
        spaceBetween={2}
        // slidesPerView={
        //   data.imageType === "Vertical"
        //     ? windowWidth > 768
        //       ? windowWidth > 1120
        //         ? windowWidth > 1280
        //           ? windowWidth > 1440
        //             ? windowWidth > 1600
        //               ? 9.2
        //               : 8
        //             : 7
        //           : 6
        //         : 5
        //       : 2.3
        //     : windowWidth > 768
        //     ? windowWidth > 1120
        //       ? windowWidth > 1280
        //         ? windowWidth > 1440
        //           ? windowWidth > 1600
        //             ? 7
        //             : 6
        //           : 5
        //         : 4.5
        //       : 4
        //     : 1.6
        // }
        slidesPerGroup={1}
        breakpoints={
          data.imageType === "Vertical"
            ? {
                0: {
                  slidesPerView: 2.3,
                },
                640: {
                  slidesPerView: 3.5,
                },
                768: {
                  slidesPerView: 4.5,
                },
                1024: {
                  slidesPerView: 5,
                },
                1280: {
                  slidesPerView: 6,
                },
                1440: {
                  slidesPerView: 7,
                },
                1600: {
                  slidesPerView: 9.2,
                },
              }
            : data.imageType === "Square"
            ? {
                0: {
                  slidesPerView: 3.2,
                },
                640: {
                  slidesPerView: 4.5,
                },
                768: {
                  slidesPerView: 5.5,
                },
                1024: {
                  slidesPerView: 6.2,
                },
                1280: {
                  slidesPerView: 7,
                },
                1440: {
                  slidesPerView: 8,
                },
                1600: {
                  slidesPerView: 9,
                },
              }
            : data.imageType === "Circle"
            ? {
                0: {
                  slidesPerView: 3.5,
                },
                640: {
                  slidesPerView: 4.5,
                },
                768: {
                  slidesPerView: 5.5,
                },
                1024: {
                  slidesPerView: 7,
                },
                1280: {
                  slidesPerView: 8,
                },
                1440: {
                  slidesPerView: 9,
                },
                1600: {
                  slidesPerView: 10,
                },
              }
            : {
                0: {
                  slidesPerView: 1.6,
                },
                640: {
                  slidesPerView: 2.5,
                },
                768: {
                  slidesPerView: 4,
                },
                1024: {
                  slidesPerView: 4.5,
                },
                1280: {
                  slidesPerView: 5,
                },
                1440: {
                  slidesPerView: 6,
                },
                1600: {
                  slidesPerView: 7,
                },
              }
        }
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        speed={600}
      >
        {data?.content
          ?.filter((item) => item.item.isEnable === true)
          ?.map((item, index) => (
            <>
              <SwiperSlide
                key={index}
                className=" cursor-pointer px-2 py-3"
                onClick={() => {
                  if (episode) {
                    onClick(index);
                  } else {
                    item.kind === "movie"
                      ? navigate(`/movie/${item.item.slug}`)
                      : navigate(`/series/${item.item.slug}`);
                  }
                }}
              >
                <img
                  src={
                    data.imageType === "Vertical"
                      ? item.item.verticalBanner
                      : data.imageType === "Square"
                      ? item.item.squareBanner
                      : data.imageType === "Circle"
                      ? item.item.squareBanner
                      : item.item.horizontalBanner
                  }
                  alt={item.item.name}
                  className={`${
                    data.imageType === "Vertical"
                      ? "aspect-[10/16]"
                      : data?.imageType === "Square"
                      ? "aspect-[1/1]"
                      : data?.imageType === "Circle"
                      ? "aspect-[1/1] rounded-full"
                      : "aspect-[16/9]"
                  } w-full rounded  bg-black object-fill  transition-all hover:scale-105 `}
                />
                <p
                  className={`
                     mt-1 text-[10px] text-text ${
                       data.imageType === "Circle" ? "text-center" : "ml-1"
                     }
                  `}
                >
                  {item.item.name}
                </p>
              </SwiperSlide>
            </>
          ))}
        {((data.imageType === "Vertical" && data?.content?.length > 5) ||
          (data.imageType !== "Vertical" && data?.content?.length > 4)) &&
          windowWidth > 768 && (
            // arrow icon
            <>
              <div className="absolute right-0 top-[38%] z-10">
                <button
                  className="rounded-full bg-primary-100 p-2"
                  onClick={() => {
                    swiperRef.current?.slideNext();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              <div className="absolute left-0 top-[38%] z-10">
                <button
                  className="rounded-full bg-primary-100 p-2"
                  onClick={() => {
                    swiperRef.current?.slidePrev();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-black"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}
      </Swiper>
    </div>
  );
};

export default VideoCard;
