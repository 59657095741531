import React from "react";

const Input = ({
  value,
  inputMode,
  placeholder,
  onChange,
  type,
  inputClass,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      inputMode={inputMode}
      value={value}
      onChange={onChange}
      className={`text-text ${inputClass} w-full rounded border border-text bg-transparent px-3 py-2.5 text-xs font-bold  outline-none focus:border-primary-100`}
    />
  );
};

export default Input;
